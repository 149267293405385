// eslint-disable-next-line import/prefer-default-export
export const schoolSignatures = {
  namespaced: true,
  state: {
    signatures: [],
  },
  mutations: {
    setSignatures(state, signatures) {
      state.signatures = signatures
    },
    addSignature: (state, signature) => {
      state.signatures = [...state.signatures, signature]
    },
    updateSignature: (state, signature) => {
      state.signatures = state.signatures.map(datum => {
        if (datum.id === signature.id) {
          return signature
        }
        return datum
      })
    },
    removeSignature: (state, id) => {
      state.signatures = state.signatures.filter(datum => +datum.id !== +id)
    },
  },
  getters: {
    signatures(state) {
      return state.signatures
    },
  },
}

// eslint-disable-next-line import/prefer-default-export
export const staff = {
  namespaced: true,
  state: {
    positions: [],
    members: [],
    departments: [],
    banks: [],
    payments: [],
    walletBanks: [],
    transactions: [],
    attendances: [],
    locations: [],
    paymentsSettings: {
      selectedMonth: null,
    },
    attendanceSettings: {},
  },
  mutations: {
    setPaymentSettings(state, date) {
      state.paymentsSettings.selectedMonth = date
    },
    setAttendanceSettings(state, settings) {
      state.attendanceSettings = settings
    },
    setTransactionsAndBanks(state, data) {
      state.transactions = data.transactions
      state.walletBanks = data.walletBanks
    },
    setStaffAttendances(state, data) {
      state.attendances = data
    },
    setLocations(state, data) {
      state.locations = data
    },
    setStaffPayments(state, data) {
      state.payments = data
    },
    setStaffDepartmentsAndPositions(state, data) {
      state.positions = data.positions
      state.members = data.staffMembers
      state.departments = data.departments
      state.banks = data.banks
    },
    addLocation: (state, location) => {
      state.locations = [...state.locations, location]
    },
    updateLocation: (state, location) => {
      state.locations = state.locations.map(datum => {
        if (datum.id === location.id) {
          return location
        }
        return datum
      })
    },
    removeLocation: (state, id) => {
      state.locations = state.locations.filter(datum => +datum.id !== +id)
    },
    addStaff: (state, member) => {
      state.members = [...state.members, member]
    },
    updateStaff: (state, member) => {
      state.members = state.members.map(datum => {
        if (datum.id === member.id) {
          return member
        }
        return datum
      })
    },
    removeStaff: (state, id) => {
      state.members = state.members.filter(datum => +datum.id !== +id)
    },
    addPosition: (state, position) => {
      state.positions = [...state.positions, position]
    },
    updatePosition: (state, position) => {
      state.positions = state.positions.map(datum => {
        if (datum.id === position.id) {
          return position
        }
        return datum
      })
    },
    removePosition: (state, id) => {
      state.positions = state.positions.filter(datum => +datum.id !== +id)
    },
    addBank: (state, walletBank) => {
      state.walletBanks = [...state.walletBanks, walletBank]
    },
    updateBank: (state, walletBank) => {
      state.walletBanks = state.walletBanks.map(datum => {
        if (datum.id === walletBank.id) {
          return walletBank
        }
        return datum
      })
    },
    removeBank: (state, id) => {
      state.walletBanks = state.walletBanks.filter(datum => +datum.id !== +id)
    },
    addDepartment: (state, department) => {
      state.departments = [...state.departments, department]
    },
    updateDepartment: (state, department) => {
      state.departments = state.departments.map(datum => {
        if (datum.id === department.id) {
          return department
        }
        return datum
      })
    },
    removeDepartment: (state, id) => {
      state.departments = state.departments.filter(datum => +datum.id !== +id)
    },
  },
  getters: {
    positions(state) {
      return state.positions
    },
    members(state) {
      return state.members
    },
    departments(state) {
      return state.departments
    },
    banks(state) {
      return state.banks
    },
    payments(state) {
      return state.payments
    },
    transactions(state) {
      return state.transactions
    },
    walletBanks(state) {
      return state.walletBanks
    },
    attendances(state) {
      return state.attendances
    },
    locations(state) {
      return state.locations
    },
    paymentsSettings(state) {
      return state.paymentsSettings
    },
    attendanceSettings(state) {
      return state.attendanceSettings
    },
  },
}

// eslint-disable-next-line import/prefer-default-export
export const assessmentSettings = {
  namespaced: true,
  state: {
    assessmentSettings: [],
  },
  mutations: {
    setAssessmentSettings(state, data) {
      state.assessmentSettings = data
    },
    removeAssessmentSettings: (state, ids) => {
      state.assessmentSettings = state.assessmentSettings.filter(datum => !ids.includes(datum.id))
    },
  },
  getters: {
    assessmentSettings(state) {
      return state.assessmentSettings
    },
  },
}

// eslint-disable-next-line import/prefer-default-export
export const studentGrading = {
  namespaced: true,
  state: {
    grades: [],
    subjects: [],
    aggregates: [],
    gradingSystems: [],
    gradingSystemGrades: [],
  },
  mutations: {
    setSubjects(state, data) {
      state.subjects = data.subjects
    },
    setGradesGradingSystemsSubjectsAggregates(state, data) {
      state.grades = data.grades
      state.gradingSystems = data.gradingSystems
      state.subjects = data.subjects
      state.aggregates = data.aggregates
    },
    addSubject: (state, subject) => {
      state.subjects = [...state.subjects, subject]
    },
    updateSubject: (state, subject) => {
      state.subjects = state.subjects.map(datum => {
        if (datum.id === subject.id) {
          return subject
        }
        return datum
      })
    },
    removeSubject: (state, id) => {
      state.subjects = state.subjects.filter(datum => datum.id !== id)
    },
    addGrade: (state, grade) => {
      state.grades = [...state.grades, grade]
    },
    updateGrade: (state, grade) => {
      state.grades = state.grades.map(datum => {
        if (datum.id === grade.id) {
          return grade
        }
        return datum
      })
    },
    removeGrade: (state, id) => {
      state.grades = state.grades.filter(datum => datum.id !== id)
    },

    addGradingSystem: (state, gs) => {
      state.gradingSystems = [...state.gradingSystems, gs]
    },
    updateGradingSystem: (state, gs) => {
      state.gradingSystems = state.gradingSystems.map(datum => {
        if (datum.id === gs.id) {
          return gs
        }
        return datum
      })
    },
    removeGradingSystem: (state, id) => {
      state.gradingSystems = state.gradingSystems.filter(datum => datum.id !== id)
    },

    setGradingSystemGrades(state, grades) {
      state.gradingSystemGrades = grades
    },
    removeGradingSystemGrade(state, gs) {
      state.gradingSystems = state.gradingSystems.map(gradingSystem => {
        if (+gradingSystem.id === gs.id) {
          // eslint-disable-next-line no-param-reassign
          gradingSystem.grades = gradingSystem.grades.filter(grade => +grade.id !== gs.gradingSystemGradeId)
          return gradingSystem
        }
        return gradingSystem
      })
    },
    addAggregate: (state, aggregate) => {
      state.aggregates = [...state.aggregates, aggregate]
    },
    updateAggregate: (state, aggregate) => {
      state.aggregates = state.aggregates.map(datum => {
        if (datum.id === aggregate.id) {
          return aggregate
        }
        return datum
      })
    },
    removeAggregate: (state, id) => {
      state.aggregates = state.aggregates.filter(datum => datum.id !== id)
    },
  },
  getters: {
    grades(state) {
      return state.grades
    },
    gradingSystems(state) {
      return state.gradingSystems
    },
    gradingSystemGrades(state) {
      return state.gradingSystemGrades
    },
    subjects(state) {
      return state.subjects
    },
    aggregates(state) {
      return state.aggregates
    },
  },

}

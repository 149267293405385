export default [
  {
    path: '/accounts',
    component: () => import('@/layouts/GeneralLayout.vue'),
    children: [
      {
        path: 'sms-account',
        name: 'sms-account',
        component: () => import('@/views/account/SmsAccount.vue'),
        meta: {
          redirectIfGuest: true,
          resource: 'accounts',
          action: 'access-sms-account',
        },
      },
      // academics
      {
        path: 'subscription',
        name: 'subscription',
        component: () => import('@/views/account/Pricing.vue'),
        meta: {
          redirectIfGuest: true,
          resource: 'accounts',
          action: 'access-subscription',
        },
      },
      {
        path: 'failed-sms',
        name: 'failed-sms',
        component: () => import('@/views/account/FailedSms.vue'),
        meta: {
          redirectIfGuest: true,
          resource: 'accounts',
          action: 'access-failed-sms',
        },
      },
      {
        path: 'guide',
        name: 'accounts-guide',
        component: () => import('@/views/admin/Index.vue'),
        meta: {
          redirectIfGuest: true,
          resource: 'lenon',
          action: 'access-lenon-app',
        },
      },
    ],
  },
]

import Vue from 'vue'
import Vuex from 'vuex'
// Modules
import localForage from 'localforage'
import VuexPersistence from 'vuex-persist'
import app from './app'
// eslint-disable-next-line import/no-cycle
import { auth } from './auth'
// eslint-disable-next-line import/no-cycle
import { termsClasses } from './admin/customizations/terms-classes'
import { studentGrading } from './admin/customizations/student-grading'
import { studentPromotion } from './admin/customizations/student-promotion'
import { reportRemarks } from './admin/customizations/report-remarks'
import { assessmentSettings } from './admin/customizations/assessment-settings'
import { accountUsers } from './admin/account-users'
import { calendarEvents } from './admin/calendar-events'
import { students } from './students/students'
import { feeSettings } from './finance/fee-settings'
import { feeProjections } from './finance/fee-projections'
import { feePayments } from './finance/fee-payments'
import { recurringFeeSettings } from './finance/recurring-fee-settings'
import { assessments } from './academics/assessments'
import { performance } from './academics/performance'
import { results } from './academics/results'
import { schoolSignatures } from './admin/signature'
import { earnings } from './finance/earnings'
import { home } from './home/index'
import { dashboard } from './dashboard/dashboard'
import { staff } from './staff/staff'
import { vehicle } from './transportation/vehicle'
import { trip } from './transportation/trip'
import { pickupPoint } from './transportation/pickupPoint'
import { tripRequest } from './transportation/tripRequest'
import { studentId } from './students/student-id'
import { reportOptions } from './admin/customizations/report-options'
// eslint-disable-next-line import/no-cycle
import { documents } from './documents'
import { timetable } from './documents/timetable'
import { dailyReports } from './admin/daily-reports'
import appConfig from './app-config'
import verticalMenu from './vertical-menu'

const vuexLocal = new VuexPersistence({
  key: 'lenon',
  storage: localForage.createInstance({
    storeName: 'lenon',
    version: 1,
  }),
  asyncStorage: true,
  modules: [
    'auth',
    'termsClasses',
    'studentGrading',
    'studentPromotion',
    'studentId',
    'reportRemarks',
    'assessmentSettings',
    'feeSettings',
    'feeProjections',
    'accountUsers',
    'feePayments',
    'recurringFeeSettings',
    'students',
    'assessments',
    'performance',
    'results',
    'schoolSignatures',
    'earnings',
    'home',
    'dashboard',
    'staff',
    'dailyReports',
    'documents',
    'timetable',
    'vehicle',
    'trip',
    'pickupPoint',
    'tripRequest',
    'reportOptions'
  ],
})

Vue.use(Vuex)

const store = new Vuex.Store({
  modules: {
    app,
    appConfig,
    verticalMenu,
    auth,
    termsClasses,
    studentGrading,
    studentPromotion,
    studentId,
    reportRemarks,
    accountUsers,
    calendarEvents,
    students,
    assessmentSettings,
    feeSettings,
    feeProjections,
    feePayments,
    recurringFeeSettings,
    assessments,
    performance,
    results,
    schoolSignatures,
    earnings,
    home,
    dashboard,
    staff,
    dailyReports,
    documents,
    timetable,
    vehicle,
    trip,
    pickupPoint,
    tripRequest,
    reportOptions,
  },
  strict: process.env.DEV,
  plugins: [vuexLocal.plugin],
})
export default store

const initialStateCopy = JSON.parse(JSON.stringify(store.state))

export function resetState() {
  store.replaceState(JSON.parse(JSON.stringify(initialStateCopy)))
}

// eslint-disable-next-line import/prefer-default-export
export const feeProjections = {
  namespaced: true,
  state: {
    feeProjections: [],
    settings: {},
    validAy: {},
  },
  mutations: {
    setFeeProjections(state, data) {
      state.feeProjections = data
    },
    setSettings(state, data) {
      state.settings = data
    },
    setValidAy(state, data) {
      state.validAy = data
    },
  },
  getters: {
    settings(state) {
      return state.settings
    },
    validAy(state) {
      return state.validAy
    },
    feeProjections(state) {
      return state.feeProjections
    },
  },

}

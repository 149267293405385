export default [
  {
    path: '/admin',
    component: () => import('@/layouts/GeneralLayout.vue'),
    children: [
      {
        path: 'school-account',
        name: 'school-account',
        component: () => import('@/views/admin/school-account/Index.vue'),
        meta: {
          redirectIfGuest: true,
          resource: 'administration',
          action: 'access-school-account',
        },
      },
      {
        path: 'account-users',
        name: 'account-users',
        component: () => import('@/views/admin/account-users/Index.vue'),
        meta: {
          redirectIfGuest: true,
          resource: 'administration',
          action: 'access-account-users',
        },
      },
      {
        path: 'notifications',
        name: 'notifications',
        component: () => import('@/views/admin/notifications/Index.vue'),
        meta: {
          redirectIfGuest: true,
          resource: 'administration',
          action: 'access-notifications',
        },
      },
      {
        path: 'reports',
        name: 'reports',
        component: () => import('@/views/admin/reports/Index.vue'),
        meta: {
          redirectIfGuest: true,
          resource: 'administration',
          action: 'access-reports',
        },
      },
      {
        path: 'terms-and-classes',
        name: 'terms-and-classes',
        component: () => import('@/views/admin/academics/terms-and-classes/Index.vue'),
        meta: {
          redirectIfGuest: true,
          resource: 'administration',
          action: 'access-terms-and-classes',
        },
      },
      // academics
      {
        path: 'student-id-cards',
        name: 'student-id-cards',
        component: () => import('@/views/admin/academics/student-id-cards/Index.vue'),
        meta: {
          redirectIfGuest: true,
          resource: 'administration',
          action: 'access-student-id-cards',
        },
      },
      {
        path: 'subjects-grading',
        name: 'subjects-grading',
        component: () => import('@/views/admin/academics/student-grading/Index.vue'),
        meta: {
          redirectIfGuest: true,
          resource: 'administration',
          action: 'access-subjects-and-grading',
        },
      },
      {
        path: 'student-promotion',
        name: 'student-promotion',
        component: () => import('@/views/admin/academics/student-promotion/Index.vue'),
        meta: {
          redirectIfGuest: true,
          resource: 'administration',
          action: 'access-student-promotion',
        },
      },
      {
        path: 'report-remarks',
        name: 'report-remarks',
        component: () => import('@/views/admin/academics/report-remarks/Index.vue'),
        meta: {
          redirectIfGuest: true,
          resource: 'administration',
          action: 'access-report-remarks',
        },
      },
      {
        path: 'report-card-options',
        name: 'report-card-options',
        component: () => import('@/views/admin/academics/report-card/Index.vue'),
        meta: {
          redirectIfGuest: true,
          resource: 'administration',
          action: 'access-report-card-options',
        },
      },
      {
        path: 'assessment-settings',
        name: 'assessment-settings',
        component: () => import('@/views/admin/academics/assessment-settings/Index.vue'),
        meta: {
          redirectIfGuest: true,
          resource: 'administration',
          action: 'access-assessment-settings',
        },
      },
      {
        path: 'document-signatures',
        name: 'document-signatures',
        component: () => import('@/views/admin/signatures/Index.vue'),
        meta: {
          redirectIfGuest: true,
          resource: 'administration',
          action: 'access-document-signatures',
        },
      }, {
        path: 'user-activities',
        name: 'user-activities',
        component: () => import('@/views/admin/user-activities/Index.vue'),
        meta: {
          redirectIfGuest: true,
          resource: 'administration',
          action: 'access-user-activities',
        },
      },
      {
        path: 'guide',
        name: 'admin-guide',
        component: () => import('@/views/admin/Index.vue'),
        meta: {
          redirectIfGuest: true,
          resource: 'lenon',
          action: 'access-lenon-app',
        },
      },
    ],
  },
]

// eslint-disable-next-line import/prefer-default-export
export const reportRemarks = {
  namespaced: true,
  state: {
    remarks: [],
  },
  mutations: {
    setRemarks(state, data) {
      state.remarks = data
    },
    addRemark: (state, remark) => {
      state.remarks = [...state.remarks, remark]
    },
    updateRemark: (state, remark) => {
      state.remarks = state.remarks.map(datum => {
        if (datum.id === remark.id) {
          return remark
        }
        return datum
      })
    },
    removeRemark: (state, id) => {
      state.remarks = state.remarks.filter(datum => +datum.id !== +id)
    },
  },
  getters: {
    remarks(state) {
      return state.remarks
    },
    modifiedRemarks(state, _, rootState) {
      return state.remarks.map(r => (
        { ...r, classes: r.classes.map(c => rootState.termsClasses.classes.find(cl => +cl.id === +c)) }
      ))
    },
  },
}

// eslint-disable-next-line import/prefer-default-export
export const assessments = {
  namespaced: true,
  state: {
    terminalAssessments: {
      columns: [],
      data: [],
    },
    settings: {},
  },
  mutations: {
    setSettings(state, settings) {
      state.settings = settings
    },
    setTerminalAssessments(state, data) {
      state.terminalAssessments = data.terminalAssessments
    },
  },
  getters: {
    assessments(state) {
      return state.terminalAssessments.data.map(datum => {
        const assessment = {}
        assessment.photo = datum.photo
        assessment.id = datum.id
        assessment.full_name = `${datum.first_name} ${datum.middle_name || ''} ${datum.last_name}`
        datum.results.forEach(result => {
          assessment[result.key] = +result.score
        })
        return assessment
      })
    },
    assessmentColumns(state) {
      return state.terminalAssessments.columns.map(c => {
        if (c.key !== 'full_name') {
          return { ...c, sortable: false }
        }
        return c
      })
    },
    previousSettings(state) {
      return state.settings
    },
  },

}

// const assessmentStructure = {
//   assessments: [
//     {
//       id: 1,
//       full_name: 'Daniel Kofi Addae',
//       results: [
//         {
//           key: 'class_test',
//           score: 26,
//         },
//         {
//           key: 'mid_term',
//           score: 20,
//         },
//         {
//           key: 'final_exam',
//           score: 83,
//         },
//         {
//           key: 'total_class_score',
//           score: 32,
//         },
//         {
//           key: 'total_exam_score',
//           score: 64,
//         },
//       ],
//     },
//   ],
//   columns: [
//     {
//       label: 'Class Test (30)',
//       key: 'class_test',
//     },
//     {
//       label: 'Mid Term (25)',
//       key: 'mid_term',
//     },
//     {
//       label: 'Final Exam (100)',
//       key: 'final_exam',
//     },
//     {
//       label: 'Toatl Class Score (40%)',
//       key: 'total_class_score',
//     },
//     {
//       label: 'Toatl Exam Score (70%)',
//       key: 'total_exam_score',
//     },
//   ],
// }

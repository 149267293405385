import Vue from 'vue'
import { ApolloClient } from 'apollo-client'
// import { HttpLink } from 'apollo-link-http'
import {
  ApolloLink, split, Observable,
} from 'apollo-link'
import { WebSocketLink } from 'apollo-link-ws'
import { getMainDefinition } from 'apollo-utilities'
import { setContext } from 'apollo-link-context'
// import { restartWebsockets } from 'vue-cli-plugin-apollo/graphql-client'
import { onError } from 'apollo-link-error'
import VueApollo from 'vue-apollo'
import { InMemoryCache } from 'apollo-cache-inmemory'
import createUploadLink from 'apollo-upload-client/public/createUploadLink'
import eventBus from '@/lenon/utils/eventBus'
// eslint-disable-next-line import/named
import { REFRESH_TOKEN_M } from '@/graphql/mutations'
import router from './router/index'
import store from './store'

Vue.use(VueApollo)

// const httpLink = new HttpLink({
//   // You should use an absolute URL here
//   uri: process.env.VUE_APP_GQL_URL,
// })

// Create the subscription websocket link
const wsLink = new WebSocketLink({
  uri: process.env.VUE_APP_WS_URL,
  options: {
    reconnect: false,
  },
})

// using the ability to split links, you can send data to each link
// depending on what kind of operation is being sent
const link = split(
  // split based on operation type
  ({ query }) => {
    const definition = getMainDefinition(query)
    return definition.kind === 'OperationDefinition' && definition.operation === 'subscription'
  },
  wsLink,
  createUploadLink({
    uri: process.env.VUE_APP_GQL_URL,
  }),

)

const authLink = setContext((_, { headers }) => {
  // get the authentication token from ApplicationSettings if it exists
  const token = store.getters['auth/accessToken']
  const user = store.getters['auth/user']

  // return the headers to the context so HTTP link can read them
  return {
    headers: {
      ...headers,
      Authorization: token ? `Bearer ${token}` : null,
      Accept: 'application/json',
      'Content-Type': 'application/json',
      'X-Auth-User': user?.id || null,
      'X-User-Type': 'Teacher',
    },
  }
})
async function getNewToken() {
  // eslint-disable-next-line no-use-before-define,no-return-await
  return await apolloClient.mutate({
    mutation: REFRESH_TOKEN_M,
    variables: {
      refresh_token: store.getters['auth/refreshToken'],
    },
  })
}

const errorLink = onError(({
  graphQLErrors, networkError, operation, forward,
// eslint-disable-next-line consistent-return
}) => {
  if (graphQLErrors) {
    // eslint-disable-next-line no-restricted-syntax
    for (const err of graphQLErrors) {
      // eslint-disable-next-line default-case
      if (err.extensions?.category === 'authentication' && router.currentRoute.meta.redirectIfGuest) {
        return new Observable(observer => {
          getNewToken().catch(() => {
            eventBus.$emit('requestError', 'refresh_token_failed')
          }).then(res => {
            store.commit('auth/updateToken', res.data?.refreshToken)
            const token = res.data.refreshToken?.access_token
            const oldHeaders = operation.getContext().headers
            // modify the operation context with a new token
            operation.setContext({
              headers: {
                ...oldHeaders,
                Authorization: `Bearer ${token}`,
              },
            })
          }).then(() => {
            const subscriber = {
              next: observer.next.bind(observer),
              error: observer.error.bind(observer),
              complete: observer.complete.bind(observer),
            }
            forward(operation).subscribe(subscriber)
          })
        })
      }
      if (err.extensions?.category === 'authentication' && router.currentRoute.name === 'unverified') {
        eventBus.$emit('requestError', 'refresh_token_failed')
      }else{
        eventBus.$emit('requestError', err.extensions?.category)
      }
      // if (err.extensions?.category === 'inactive') {
      //   eventBus.$emit('requestError', 'inactive')
      // }
      // if (err.extensions?.category === 'validation') {
      //   eventBus.$emit('requestError', 'validation')
      // }
      // if (err.extensions?.category === 'internal') {
      //   eventBus.$emit('requestError', 'internal')
      // }
      // if (err.extensions?.category === 'login_failure') {
      //   eventBus.$emit('requestError', 'login_failure')
      // }
      // if (err.extensions?.category === 'invalid_academic_year') {
      //   eventBus.$emit('requestError', 'invalid_academic_year')
      // }
      // if (err.extensions?.category === 'subscription_ended') {
      //   eventBus.$emit('requestError', 'subscription_ended')
      // }
      // if (err.extensions?.category === 'not_verified') {
      //   eventBus.$emit('requestError', 'not_verified')
      // }
      // if (err.extensions?.category === 'module_not_available') {
      //   eventBus.$emit('requestError', 'module_not_available')
      // }
      // if (err.extensions?.category === 'use_reset_button') {
      //   eventBus.$emit('requestError', 'use_reset_button')
      // }
      // if (err.extensions?.category === 'academic_year_completion') {
      //   eventBus.$emit('requestError', 'academic_year_completion')
      // }
      // if (err.extensions?.category === 'multiple_active_terms') {
      //   eventBus.$emit('requestError', 'multiple_active_terms')
      // }
    }
  }
  if (networkError) {
    console.log(networkError.graphQLErrors)
    console.log(`[Network error]: ${networkError}`)
  }
})
const defaultOptions = {
  watchQuery: {
    fetchPolicy: 'no-cache',
    errorPolicy: 'ignore',
  },
  query: {
    fetchPolicy: 'no-cache',
    errorPolicy: 'all',
  },
}
// Create the apollo client
const apolloClient = new ApolloClient({
  link: ApolloLink.from([
    errorLink, authLink, link,
  ]),
  cache: new InMemoryCache({
    addTypename: false,
  }),
  connectToDevTools: true,
  resolvers: {},
  defaultOptions,
})

// // Manually call this when user log in
// export async function onLogin() {
//   if (apolloClient.wsClient) restartWebsockets(apolloClient.wsClient)
// }
//
// // Manually call this when user log out
// export async function onLogout() {
//   if (apolloClient.wsClient) restartWebsockets(apolloClient.wsClient)
// }

// eslint-disable-next-line import/prefer-default-export
export const apolloProvider = new VueApollo({
  defaultClient: apolloClient,
})

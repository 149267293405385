// eslint-disable-next-line import/prefer-default-export
export const results = {
  namespaced: true,
  state: {
    results: {},
    settings: {},
  },
  mutations: {
    setSettings(state, settings) {
      state.settings = settings
    },
    setResults(state, data) {
      state.results = data
    },
  },
  getters: {
    results(state) {
      return state.results.students_results
    },
    previousSettings(state) {
      return state.settings
    },
  },

}

import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  methods: {
    showInfo(message) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: 'Information',
          icon: 'InfoIcon',
          text: message,
          variant: 'warning',
        },
      })
    },

    showSuccess(message) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: 'Success',
          icon: 'CheckCircleIcon',
          text: message,
          variant: 'success',
        },
      })
    },

    showError(message) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: 'Error',
          icon: 'XCircleIcon',
          text: message,
          variant: 'danger',
        },
      })
    },
  },
}

import { $themeBreakpoints } from '@themeConfig'

export default {
  namespaced: true,
  state: {
    windowWidth: 0,
    shallShowOverlay: false,
  },
  getters: {
    currentBreakPoint: state => {
      const { windowWidth } = state
      if (windowWidth >= $themeBreakpoints.xl) return 'xl'
      if (windowWidth >= $themeBreakpoints.lg) return 'lg'
      if (windowWidth >= $themeBreakpoints.md) return 'md'
      if (windowWidth >= $themeBreakpoints.sm) return 'sm'
      return 'xs'
    },
    isLargeScreen(state, getters) {
      return getters.currentBreakPoint === 'xl' || getters.currentBreakPoint === 'lg'
    },
    smallScreen() {
      return ['hidden-lg', 'hidden-xl', 'xs', 'sm', 'md']
    },
    largeScreen() {
      return ['hidden-xs', 'hidden-sm', 'hidden-md', 'lg', 'xl']
    },
    mediumScreen() {
      return ['hidden-xs', 'sm', 'md', 'lg', 'xl']
    },
  },
  mutations: {
    UPDATE_WINDOW_WIDTH(state, val) {
      state.windowWidth = val
    },
    TOGGLE_OVERLAY(state, val) {
      state.shallShowOverlay = val !== undefined ? val : !state.shallShowOverlay
    },
    setCurrentFormRef(state) { },
  },
  actions: {},
}

// eslint-disable-next-line import/prefer-default-export
import http from '@/libs/axios'

export const studentId = {
  namespaced: true,
  state: {
    customization: {},
  },
  mutations: {
    setCustomization(state, data) {
      state.customization = data
    },
  },
  actions: {
    updateCustomization({ commit }, data) {
      return new Promise((resolve, reject) => {
        http.put(`system/customizations/${data.type}`, data).then(res => {
          commit('setCustomization', res.data)
          resolve(true)
        }).catch(err => {
          reject(err)
        })
      })
    },
    getCustomization({ commit }, type) {
      return new Promise((resolve, reject) => {
        http.get(`system/customizations/${type}`).then(res => {
          commit('setCustomization', res.data)
          resolve(true)
        }).catch(err => {
          reject(err)
        })
      })
    },
  },
  getters: {
    idCustomization(state) {
      // for now hard code to retrieve type 0
      return state.customization
    },
  },
}

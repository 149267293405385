// eslint-disable-next-line import/prefer-default-export
export const performance = {
  namespaced: true,
  state: {
    performances: [],
    settings: {},
  },
  mutations: {
    setSettings(state, settings) {
      state.settings = settings
    },
    setPerformances(state, data) {
      state.performances = data
    },
  },
  getters: {
    performances(state) {
      return state.performances
    },
    previousSettings(state) {
      return state.settings
    },
  },

}

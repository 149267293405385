// eslint-disable-next-line import/prefer-default-export
export const pickupPoint = {
  namespaced: true,
  state: {
    pickupPoints: [],
  },
  mutations: {
    setPickupPoints(state, data) {
      state.pickupPoints = data
    },
    addPickupPoint: (state, pickupPoint) => {
      state.pickupPoints = [...state.pickupPoints, pickupPoint]
    },
    updatePickupPoint: (state, pickupPoint) => {
      state.pickupPoints = state.pickupPoints.map(datum => {
        if (datum.id === pickupPoint.id) {
          return pickupPoint
        }
        return datum
      })
    },
    removePickupPoint: (state, id) => {
      state.pickupPoints = state.pickupPoints.filter(datum => datum.id !== id)
    },
  },
  getters: {
    pickupPoints(state) {
      return state.pickupPoints
    },
  },

}

export default [
  {
    path: '/staff',
    component: () => import('@/layouts/GeneralLayout.vue'),
    children: [
      {
        path: 'staff-income',
        name: 'staff-income',
        component: () => import('@/views/staff/members/Index.vue'),
        meta: {
          redirectIfGuest: true,
          resource: 'staff',
          action: 'access-income',
        },
      },
      {
        path: 'staff-arrears',
        name: 'staff-arrears',
        component: () => import('@/views/staff/payments/Index.vue'),
        meta: {
          redirectIfGuest: true,
          resource: 'staff',
          action: 'access-arrears',
        },
      },
      {
        path: 'wallet',
        name: 'wallet',
        component: () => import('@/views/staff/wallet/Index.vue'),
        meta: {
          redirectIfGuest: true,
          resource: 'staff',
          action: 'access-wallet',
        },
      },
      {
        path: 'staff-attendance',
        name: 'staff-attendance',
        component: () => import('@/views/staff/attendance/Index.vue'),
        meta: {
          redirectIfGuest: true,
          resource: 'staff',
          action: 'access-wallet',
        },
      },
      {
        path: 'guide',
        name: 'staff-guide',
        component: () => import('@/views/admin/Index.vue'),
        meta: {
          redirectIfGuest: true,
          resource: 'lenon',
          action: 'access-lenon-app',
        },
      },
    ],
  },
]

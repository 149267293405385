// eslint-disable-next-line import/prefer-default-export
import { resetState } from '@/store'
import http from '@/libs/axios'

export const auth = {
  namespaced: true,
  state: {
    isRefreshing: false,
    refreshingCall: false,
    auth: {
      user: {},
      token: {},
      school: {
        // creche_classes: [],
      },
      current_academic_year: {},
      role: '',
      permissions: [],
      bank: {},
      address: {},
      has_multiple_accounts: false,
      has_multiple_terms: false,
    },
    banks: [],
    countries: [],
    regions: [],
    cities: [],
    currency: 'GHS',
    userActivities: {},
    sms_balance: 0,
    current_module: null,
    pricing: [],
    subscription: {},
    modules: [],
    totalStudents: 0,
    remember_me: false,
  },
  mutations: {
    setModules(state, modules) {
      state.modules = modules
    },
    setSubAndPricing(state, data) {
      state.subscription = data
      state.totalStudents = data?.total_students
    },
    setCurrentAcademicYear(state, data) {
      state.auth.current_academic_year = data.currentAcademicYear
      state.auth.has_multiple_accounts = data.hasMultipleAccounts
      state.auth.has_multiple_terms = data.hasMultipleTerms
    },
    updateCurrentAcademicYear(state, data) {
      state.auth.current_academic_year = { ...state.auth.current_academic_year, ...data }
    },
    setCurrentModule(state, module) {
      state.current_module = module
    },
    setSmsBalance(state, balance) {
      state.sms_balance = balance
    },
    removePhoto(state) {
      state.auth.user.photo = null
    },
    setUserActivities(state, activities) {
      state.userActivities = activities.userActivities
    },
    setAddresses(state, data) {
      state.countries = data.countries
      state.regions = data.regions
      state.cities = data.cities
    },
    setBanksAddresses(state, data) {
      state.banks = data.banks
      state.countries = data.countries
      state.regions = data.regions
      state.cities = data.cities
      state.auth.address = data.address
      state.auth.school = { ...state.auth.school, ...data.school }
      state.auth.bank = data.bank
    },
    updateBank(state, bank) {
      state.auth.bank = bank
    },
    updateLogo(state, logo) {
      state.auth.school.logo = logo
    },
    updateAddress(state, address) {
      state.auth.address = address
    },
    updateToken(state, token) {
      state.auth.token = token
    },
    setRefreshingState(state,data){
      state.setRefreshingState = data
    },
    setRefreshingCall(state,data){
      state.setRefreshingCall = data
    },
    setUserData: (state, userData) => {
      state.auth = { ...state.auth, ...userData }
    },
    setRememberMe: (state, r) => {
      state.remember_me = r
    },
    // eslint-disable-next-line no-unused-vars
    clearAuthState(state) {
      localStorage.removeItem('permissions')
      localStorage.removeItem('role')
      resetState()
    },
    setLoggedOut(state) {
      state.auth.token.access_token = null
    },
    updateUser(state, user) {
      state.auth.user = { ...state.auth.user, ...user }
    },
    updateUserPhoto(state, photo) {
      state.auth.user.photo = photo
    },
    updateSchool(state, school) {
      state.auth.school = { ...state.auth.school, ...school }
    },
  },
  actions: {
    initiateTopUp({ commit }, amount) {
      return new Promise((resolve, reject) => {
        http.post('accounts/initiate-sms-top-up', { amount }).then(res => {
          resolve(res)
        }).catch(err => {
          reject(err)
        })
      })
    },
  },
  getters: {
    totalStudents(state) {
      return state.totalStudents
    },
    role(state) {
      return state.auth.role
    },
    permissions(state) {
      return state.auth.permissions
    },
    formattedPermissions(state) {
      let { permissions } = state.auth
      permissions = permissions?.map(p => ({
        subject: p.resource,
        action: p.name,
      }))
      if (state.auth.role === 'Administrator') {
        permissions = null
      }
      if (permissions !== null) {
        // eslint-disable-next-line no-unused-expressions
        permissions?.push({
          subject: 'lenon',
          action: 'access-lenon-app',
        })
      }
      return permissions
    },
    isCustom(state) {
      return state.auth.current_academic_year.custom
    },
    accessToken(state) {
      return state.auth?.token?.access_token
    },
    refreshToken(state) {
      return state.auth?.token?.refresh_token
    },
    appBarData(state) {
      const isCustom = state.auth?.current_academic_year?.custom
      return {
        first_name: state.auth?.user?.first_name,
        role: state.auth?.role,
        school_name: state.auth?.school.name,
        alias: state.auth?.school.alias,
        has_academic_year: !!state.auth?.current_academic_year,
        short_title: `${state.auth?.current_academic_year?.term}${state.auth?.current_academic_year?.tag ? ` (${state.auth?.current_academic_year?.tag})` : ''} - ${state.auth?.current_academic_year?.name}`,
        title: `${state.auth?.current_academic_year?.term}${state.auth?.current_academic_year?.tag ? ` (${state.auth?.current_academic_year?.tag})` : ''} - ${state.auth?.current_academic_year?.name} Academic Year ${isCustom ? '!!! (NB: THIS IS NOT THE CURRENT ACADEMIC YEAR) !!!' : ''}`,
      }
    },
    academicYear(state) {
      return state.auth?.current_academic_year
    },
    user(state) {
      return state.auth.user
    },
    verified(state) {
      return state.auth?.school?.verified
    },
    userProfile(state) {
      return state.auth.user?.photo ? `${state.auth.user?.photo}` : null
    },
    schoolName(state) {
      return state.auth.school.name
    },
    schoolCode(state) {
      return state.auth.school.verification_code
    },
    schoolLogo(state) {
      return state.auth.school?.logo ? state.auth.school?.logo : null
    },
    school(state) {
      return state.auth.school
    },
    bank(state) {
      return state.auth.bank
    },
    banks(state) {
      return state.banks
    },
    countries(state) {
      return state.countries
    },
    regions(state) {
      return state.regions
    },
    cities(state) {
      return state.cities
    },
    address(state) {
      return state.auth.address
    },
    currency(state) {
      return state.currency
    },
    userActivities(state) {
      return state.userActivities
    },
    smsBalance(state) {
      return state.sms_balance
    },
    currentModule(state) {
      return state.current_module
    },
    trialEndDate(state) {
      return state.auth.school.trial_ends
    },
    pricing(state) {
      return state.pricing
    },
    subscription(state) {
      return state.subscription
    },
    schoolModules(state) {
      return state.modules
    },
    rememberMe(state) {
      return state.remember_me
    },
    hasMultipleTerms(state) {
      return state.auth.has_multiple_terms
    },
    hasMultipleAccounts(state) {
      return state.auth.has_multiple_accounts
    },
  },
}

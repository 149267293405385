export default [
  {
    path: '/transportation',
    component: () => import('@/layouts/GeneralLayout.vue'),
    children: [
      {
        path: 'setup-trips',
        name: 'setup-trips',
        component: () => import('@/views/transportation/trips/setup/Index.vue'),
        meta: {
          redirectIfGuest: true,
          resource: 'transportation',
          action: 'access-setup-trips',
        },
      },
      {
        path: 'trip-requests',
        name: 'trip-requests',
        component: () => import('@/views/transportation/trips/requests/Index.vue'),
        meta: {
          redirectIfGuest: true,
          resource: 'transportation',
          action: 'access-trip-requests',
        },
      },
      {
        path: 'completed-trips',
        name: 'completed-trips',
        component: () => import('@/views/transportation/trips/completed/Index.vue'),
        meta: {
          redirectIfGuest: true,
          resource: 'transportation',
          action: 'access-completed-trips',
        },
      },
      {
        path: 'trip-attendance',
        name: 'trip-attendance',
        component: () => import('@/views/transportation/trips/attendance/Index.vue'),
        meta: {
          redirectIfGuest: true,
          resource: 'transportation',
          action: 'access-trip-attendance',
        },
      },
      {
        path: 'vehicles',
        name: 'vehicles',
        component: () => import('@/views/transportation/settings/vehicles/Index.vue'),
        meta: {
          redirectIfGuest: true,
          resource: 'transportation',
          action: 'access-vehicles',
        },
      },
      {
        path: 'pickup-points',
        name: 'pickup-points',
        component: () => import('@/views/transportation/settings/pickup/Index.vue'),
        meta: {
          redirectIfGuest: true,
          resource: 'transportation',
          action: 'access-pickup-points',
        },
      },
      {
        path: 'guide',
        name: 'transportation-guide',
        component: () => import('@/views/admin/Index.vue'),
        meta: {
          redirectIfGuest: true,
          resource: 'lenon',
          action: 'access-lenon-app',
        },
      },
    ],
  },
]

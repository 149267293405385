// eslint-disable-next-line import/prefer-default-export
export const termsClasses = {
  namespaced: true,
  state: {
    teachers: [],
    classes: [],
    academicYearClasses: [],
    terms: [],
    schoolTerms: [],
    tags: [],
    academic_years: [],
    defaultAy: null,
    promotionFlow: [],
  },
  mutations: {
    setTeachers(state, teachers) {
      state.teachers = teachers
    },
    updatePromotionFlow(state, flow) {
      state.promotionFlow = flow
    },
    setTerms(state, data) {
      state.terms = data.terms
    },
    setSchoolTerms(state, data) {
      state.schoolTerms = data.schoolTerms
    },
    setDefaultAy(state, data) {
      state.defaultAy = data
    },
    setAyClasses(state, data) {
      state.academicYearClasses = data.academicYearClasses
    },
    setClasses(state, data) {
      state.classes = data.classes
    },
    setAcademicYears(state, data) {
      state.academic_years = data.academicYears
    },
    setClassesTermsAndTeachers(state, data) {
      state.classes = data.academicYearClasses
      state.academicYearClasses = data.academicYearClasses
      state.terms = data.terms
      state.schoolTerms = data.schoolTerms
      state.teachers = data.teachers
      state.academic_years = data.academicYears
      state.defaultAy = data.defaultAy
      state.tags = data.tags
    },
    addClass: (state, studentClass) => {
      state.classes = [...state.classes, studentClass]
    },
    updateClass: (state, studentClass) => {
      state.classes = state.classes.map(datum => {
        if (datum.id === studentClass.id) {
          return studentClass
        }
        return datum
      })
    },
    removeClass: (state, id) => {
      state.classes = state.classes.filter(datum => datum.id !== id)
    },
    addTerm: (state, studentTerm) => {
      state.schoolTerms = [...state.schoolTerms, studentTerm]
    },
    updateTerm: (state, studentTerm) => {
      state.schoolTerms = state.schoolTerms.map(datum => {
        if (datum.id === studentTerm.id) {
          return studentTerm
        }
        return datum
      })
    },
    removeTerm: (state, id) => {
      state.schoolTerms = state.schoolTerms.filter(datum => +datum.id !== +id)
    },

    addTag: (state, tag) => {
      state.tags = [...state.tags, tag]
    },
    updateTag: (state, tag) => {
      state.tags = state.tags.map(datum => {
        if (datum.id === tag.id) {
          return tag
        }
        return datum
      })
    },
    removeTag: (state, id) => {
      state.tags = state.tags.filter(datum => +datum.id !== +id)
    },
    updateAcademicYear(state, ay) {
      let updated = false
      state.academic_years = state.academic_years.map(a => {
        if (+a.id === +ay.id) {
          updated = true
          return ay
        }
        return a
      })
      if (!updated) {
        state.academic_years = [ay, ...state.academic_years]
      }
    },
    removeAcademicYearTerm: (state, ay) => {
      const foundAcademicYear = state.academic_years.find(a => +a.id === +ay.id)
      foundAcademicYear.terms = foundAcademicYear.terms.filter(
        academicYearTerm => +academicYearTerm.id !== +ay.academicYearTermId,
      )
    },
    removeAcademicYear: (state, id) => {
      state.academic_years = state.academic_years.filter(datum => +datum.id !== +id)
    },
  },
  getters: {
    termClasses(state, b, a, rootGetters) {
      const ay = rootGetters['auth/academicYear']
      let classes = []
      if (ay.tag) {
        const term = state.terms.find(t => +t.ay_term_id === +ay.ay_term_id)
        classes = term.classes
        return state.classes.filter(c => classes.includes(c.id))
      }
      return state.classes
    },
    classes(state) {
      return state.classes
    },
    allClasses(state) {
      return state.classes
    },
    defaultAy(state) {
      return state.defaultAy
    },
    isEnabled(state) {
      return state.defaultAy?.enabled
    },
    academicYearClasses(state) {
      return state.academicYearClasses
    },
    terms(state) {
      return state.terms
    },
    schoolTerms(state) {
      return state.schoolTerms
    },
    tags(state) {
      return state.tags
    },
    teachers(state) {
      return state.teachers.map(t => ({
        id: t.id,
        fullName: `${t.first_name} ${t.last_name}`,
      }))
    },
    academicYears(state) {
      return state.academic_years
    },
    currentAcademicYear(state) {
      let c = state.academic_years?.find(ay => ay.current)
      if (!c && state.academic_years.length) {
        // eslint-disable-next-line prefer-destructuring
        c = state.academic_years[0]
      }
      return c
    },
    promotionFlow(state) {
      return state.promotionFlow
    },
  },
}

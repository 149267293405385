export default [
  {
    path: '/documents',
    component: () => import('@/layouts/GeneralLayout.vue'),
    children: [
      {
        path: 'documents',
        name: 'documents',
        component: () => import('@/views/documents/folders/Index.vue'),
        meta: {
          redirectIfGuest: true,
          resource: 'documents',
          action: 'access-documents',
        },
      },
      {
        path: 'timetable',
        name: 'timetable',
        component: () => import('@/views/documents/timetable/Index.vue'),
        meta: {
          redirectIfGuest: true,
          resource: 'timetable',
          action: 'access-timetable',
        },
      },
      {
        path: 'questions',
        name: 'questions',
        component: () => import('@/views/documents/questions/Index.vue'),
        meta: {
          redirectIfGuest: true,
          resource: 'questions',
          action: 'access-questions',
        },
      },
      {
        path: 'credits',
        name: 'credits',
        component: () => import('@/views/documents/credits/Index.vue'),
        meta: {
          redirectIfGuest: true,
          resource: 'credits',
          action: 'access-credits',
        },
      },
      {
        path: 'trash-store',
        name: 'trash-store',
        component: () => import('@/views/documents/trash/Index.vue'),
        meta: {
          redirectIfGuest: true,
          resource: 'trash-store',
          action: 'access-trash-store',
        },
      },
      {
        path: 'lesson-notes',
        name: 'lesson-notes',
        component: () => import('@/views/documents/lesson/Index.vue'),
        meta: {
          redirectIfGuest: true,
          resource: 'lesson-notes',
          action: 'access-lesson-notes',
        },
      },
      // {
      //   path: 'edit-questions',
      //   name: 'edit-questions',
      //   component: () => import('@/views/documents/questions/QuestionsEditor.vue'),
      //   meta: {
      //     layout: 'full',
      //     redirectIfGuest: true,
      //     resource: 'lenon',
      //     action: 'access-lenon-app',
      //   },
      // },
      {
        path: 'guide',
        name: 'docs-guide',
        component: () => import('@/views/admin/Index.vue'),
        meta: {
          redirectIfGuest: true,
          resource: 'lenon',
          action: 'access-lenon-app',
        },
      },
    ],
  },
]

// eslint-disable-next-line import/prefer-default-export
export const feeSettings = {
  namespaced: true,
  state: {
    feeSettings: [],
  },
  mutations: {
    setFeeSettings(state, data) {
      state.feeSettings = data.feeSettings
    },
    addFeeSetting: (state, feeSetting) => {
      state.feeSettings = [feeSetting, ...state.feeSettings]
    },
    updateFeeSetting: (state, feeSetting) => {
      state.feeSettings = state.feeSettings.map(datum => {
        if (datum.id === feeSetting.id) {
          return feeSetting
        }
        return datum
      })
    },
    removeFeeSetting: (state, id) => {
      state.feeSettings = state.feeSettings.filter(datum => datum.id !== id)
    },
  },
  getters: {
    feeSettings(state) {
      return state.feeSettings
    },
  },
}

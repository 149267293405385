// eslint-disable-next-line import/prefer-default-export
// import store from '@/store'

export const home = {
  namespaced: true,
  actions: {
    setRequiredData({ commit }, data) {
      commit('feeSettings/setFeeSettings', data, { root: true })
      commit('termsClasses/setClasses', data, { root: true })
      commit('termsClasses/setAyClasses', data, { root: true })
      commit('termsClasses/setTerms', data, { root: true })
      commit('termsClasses/setSchoolTerms', data, { root: true })
      commit('termsClasses/setAcademicYears', data, { root: true })
      commit('studentGrading/setSubjects', data, { root: true })
      commit('accountUsers/setUsers', data, { root: true })
    },
  },
}

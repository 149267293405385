// eslint-disable-next-line import/prefer-default-export
export const studentPromotion = {
  namespaced: true,
  state: {
    promotionStandards: [],
    promotionFlow: [],
  },
  mutations: {
    setPromotionStandards(state, data) {
      state.promotionStandards = data
    },
    setPromotionFlow(state, data) {
      state.promotionFlow = data
    },
    addPromotionStandard: (state, promotionStandard) => {
      state.promotionStandards = [...state.promotionStandards, promotionStandard]
    },
    updatePromotionStandard: (state, promotionStandard) => {
      state.promotionStandards = state.promotionStandards.map(datum => {
        if (datum.id === promotionStandard.id) {
          return promotionStandard
        }
        return datum
      })
    },
    removePromotionStandard: (state, id) => {
      state.promotionStandards = state.promotionStandards.filter(datum => +datum.id !== +id)
    },
  },
  getters: {
    promotionStandards(state) {
      return state.promotionStandards
    },
    promotionFlow(state) {
      return state.promotionFlow
    },
    modifiedPromotionStandards(state, _, rootState) {
      return state.promotionStandards
      // return state.promotionStandards.map(p => (
      //   { ...p, classes: p.classes.map(c => rootState.reportRemarks.classes.find(cl => +cl.id === +c)) }
      // ))
    },
  },
}

import http from '@/libs/axios'

export const timetable = {
  namespaced: true,
  state: {
    timeTables: [],
    lessonNotes: [],
    settings: {
      selectedAcademicYearId: null,
      selectedTermId: null,
    },
    lessonNotesSettings: {
      selectedAcademicYearId: null,
      selectedTermId: null,
    },
  },
  mutations: {
    setLessonNotesSettings: (state, settings) => {
      state.lessonNotesSettings = { ...state.lessonNotesSettings, ...settings }
    },
    setLessonNotes: (state, lessonNotes) => {
      state.lessonNotes = lessonNotes
    },
    setSettings: (state, settings) => {
      state.settings = { ...state.settings, ...settings }
    },
    setTimeTables: (state, timeTables) => {
      state.timeTables = timeTables
    },
    addTimeTable: (state, file) => {
      state.timeTables = [file, ...state.timeTables]
    },
    updateTimeTable: (state, file) => {
      state.timeTables = state.timeTables.map(datum => {
        if (datum.id === file.id) {
          return file
        }
        return datum
      })
    },
    removeTimeTable: (state, id) => {
      state.timeTables = state.timeTables.filter(datum => datum.id !== id)
    },
  },
  actions: {
    createTimeTable({ commit }, data) {
      return new Promise((resolve, reject) => {
        http.post('documents/timetables', data).then(res => {
          commit('addTimeTable', res.data)
          resolve(true)
        }).catch(err => {
          reject(err)
        })
      })
    },
    updateTimeTable({ commit }, data) {
      return new Promise((resolve, reject) => {
        http.put(`documents/timetables/${data.id}`, data).then(res => {
          commit('updateTimeTable', res.data)
          resolve(true)
        }).catch(err => {
          reject(err)
        })
      })
    },
    deleteTimeTable({ commit }, id) {
      return new Promise((resolve, reject) => {
        http.delete(`documents/timetables/${id}`).then(res => {
          commit('removeTimeTable', id)
          resolve(true)
        }).catch(err => {
          reject(err)
        })
      })
    },
    fetchTimeTables({ commit }, query) {
      return new Promise((resolve, reject) => {
        http.get(`documents/timetables?term_id=${query.term_id}&ay_id=${query.ay_id}`)
          .then(res => {
            commit('setTimeTables', res.data)
            resolve(true)
          }).catch(err => {
            reject(err)
          })
      })
    },
    generateDocument({ commit }, id) {
      return new Promise((resolve, reject) => {
        http.get(`documents/timetables/${id}`)
          .then(res => {
            resolve(res.data)
          }).catch(err => {
            reject(err)
          })
      })
    },
    fetchLessonNotes({ commit }, query) {
      return new Promise((resolve, reject) => {
        http.get(`documents/dashboard-lesson-notes?term_id=${query.term_id}&ay_id=${query.ay_id}`)
          .then(res => {
            commit('setLessonNotes', res.data)
            resolve(true)
          }).catch(err => {
            reject(err)
          })
      })
    },
    downloadLessonNote({ commit }, id) {
      return new Promise((resolve, reject) => {
        http.get(`documents/download-lesson-notes/${id}`)
          .then(res => {
            resolve(res.data)
          }).catch(err => {
            reject(err)
          })
      })
    },
  },
  getters: {
    timeTables(state) {
      return state.timeTables
    },
    settings(state) {
      return state.settings
    },
    lessonNotes(state) {
      return state.lessonNotes
    },
    lessonNotesSettings(state) {
      return state.lessonNotesSettings
    },

  },
}

// eslint-disable-next-line import/prefer-default-export
export const reportOptions = {
  namespaced: true,
  state: {
    options: [],
  },
  mutations: {
    setReportOptions(state, data) {
      state.options = data
    },
    addReportOption: (state, option) => {
      state.options = [...state.options, option]
    },
    updateReportOption: (state, option) => {
      state.options = state.options.map(datum => {
        if (datum.id === option.id) {
          return option
        }
        return datum
      })
    },
    removeReportOption: (state, id) => {
      state.options = state.options.filter(datum => +datum.id !== +id)
    },
  },
  getters: {
    reportOptions(state) {
      return state.options
    },
  },
}

// eslint-disable-next-line import/prefer-default-export
export const dashboard = {
  namespaced: true,
  state: {
    students: {
      population: {
        active: 0,
        females: 0,
        inactive: 0,
        males: 0,
      },
      ageGroup: [],
      absentees: [],
      attendanceToday: {
        females_absent: 0,
        females_present: 0,
        males_absent: 0,
        males_present: 0,
      },
    },
    finance: {},
  },
  mutations: {
    setStudentsData(state, data) {
      state.students = data
    },
    setFinanceDashboardData(state, data) {
      state.finance = data
    },
    setRevenue(state, data) {
      state.finance.revenueReport = data.revenueReport
    },
  },
  getters: {
    students(state) {
      return state.students
    },
    finance(state) {
      return state.finance
    },
  },

}

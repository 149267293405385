// eslint-disable-next-line import/prefer-default-export
export const calendarEvents = {
  namespaced: true,
  state: {
    calendarOptions: [
      {
        color: 'success',
        label: 'Processed',
      },
      {
        color: 'danger',
        label: 'Pending',
      },
    ],
    selectedCalendars: ['Processed', 'Pending'],
    events: [],
    clickedDate: null,
    clickedEvent: null,
    alerts:[],
  },
  getters: {
    calendarOptions(state) {
      return state.calendarOptions
    },
    selectedCalendars(state) {
      return state.selectedCalendars
    },
    events(state) {
      return state.events.filter(ev => state.selectedCalendars.includes(ev.extendedProps.calendar))
    },
    allEvents(state) {
      return state.events
    },
    clickedDate(state) {
      return state.clickedDate
    },
    clickedEvent(state) {
      return state.clickedEvent
    },
    alerts(state) {
      return state.alerts
    },
  },
  mutations: {
    setAlerts(state, val) {
      state.alerts = val
    },
    setSelectedEvent(state, val) {
      state.selectedCalendars = val
    },
    setEvents(state, val) {
      state.events = val
    },
    addEvent: (state, event) => {
      state.events.data = [event,...state.events.data]
    },
    updateEvent: (state, event) => {
      state.events.data = state.events.data.map(datum => {
        if (datum.id === event.id) {
          return event
        }
        return datum
      })
    },
    removeEvent: (state, id) => {
      state.events.data = state.events.data.filter(datum => +datum.id !== +id)
    },
    addAlert: (state, alert) => {
      state.alerts = [alert,...state.alerts]
    },
    updateAlert: (state, alert) => {
      state.alerts = state.alerts.map(datum => {
        if (datum.id === alert.id) {
          return alert
        }
        return datum
      })
    },
    removeAlert: (state, id) => {
      state.alerts = state.alerts.filter(datum => +datum.id !== +id)
    },
  },
}

export default [
  {
    path: '/students',
    component: () => import('@/layouts/GeneralLayout.vue'),
    children: [
      {
        path: 'registration',
        name: 'registration',
        component: () => import('@/views/students/registration/Index.vue'),
        meta: {
          redirectIfGuest: true,
          resource: 'students',
          action: 'access-registration',
        },
      },
      // academics
      {
        path: 'attendance',
        name: 'attendance',
        component: () => import('@/views/students/attendance/Index.vue'),
        meta: {
          redirectIfGuest: true,
          resource: 'students',
          action: 'access-trips',
        },
      },
      {
        path: 'old-students',
        name: 'old-students',
        component: () => import('@/views/students/old-students/Index.vue'),
        meta: {
          redirectIfGuest: true,
          resource: 'students',
          action: 'access-old-students',
        },
      },
      {
        path: 'unmarked-attendances',
        name: 'unmarked-attendances',
        component: () => import('@/views/students/unmarked/Index.vue'),
        meta: {
          redirectIfGuest: true,
          resource: 'students',
          action: 'access-unmarked-trips',
        },
      },
      {
        path: 'id-customization',
        name: 'id-customization',
        component: () => import('@/views/students/id-card/Index.vue'),
        meta: {
          redirectIfGuest: true,
          resource: 'students',
          action: 'access-id-customization',
        },
      },
      {
        path: 'guide',
        name: 'students-guide',
        component: () => import('@/views/admin/Index.vue'),
        meta: {
          redirectIfGuest: true,
          resource: 'lenon',
          action: 'access-lenon-app',
        },
      },
    ],
  },
]

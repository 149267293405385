import ability from './ability'

function toggleSideMenu() {
  if (document.getElementsByClassName('sidenav-overlay').length) {
    document.getElementsByClassName('sidenav-overlay')[0]
      .setAttribute('class', 'sidenav-overlay')
  }
  if (document.getElementsByClassName('vertical-layout').length) {
    document.getElementsByClassName('vertical-layout')[0]
      .setAttribute('class', '  vertical-layout h-100 vertical-overlay-menu menu-hide navbar-floating footer-static')
  }
}
export const canNavigate = to => to.matched.some(route => {
  if( window.screen.width<1200){
    toggleSideMenu()
  }
  return ability.can(route.meta.action || 'read', route.meta.resource)
})

export const _ = undefined

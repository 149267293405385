// eslint-disable-next-line import/prefer-default-export
export const vehicle = {
  namespaced: true,
  state: {
    vehicles: [],
  },
  mutations: {
    setVehicles(state, data) {
      state.vehicles = data
    },
    addVehicle: (state, vehicle) => {
      state.vehicles = [...state.vehicles, vehicle]
    },
    updateVehicle: (state, vehicle) => {
      state.vehicles = state.vehicles.map(datum => {
        if (datum.id === vehicle.id) {
          return vehicle
        }
        return datum
      })
    },
    removeVehicle: (state, id) => {
      state.vehicles = state.vehicles.filter(datum => datum.id !== id)
    },
  },
  getters: {
    vehicles(state) {
      return state.vehicles
    },
  },

}

import { Ability } from '@casl/ability'
import { initialAbility } from './config'

//  Read ability from localStorage
// * Handles auto fetching previous abilities if already logged in user
// ? You can update this if you store user abilities to more secure place
// ! Anyone can update localStorage so be careful and please update this
// const userData = JSON.parse(localStorage.getItem('userData'))
const role = localStorage.getItem('role')
let permissions = JSON.parse(localStorage.getItem('permissions')) || null
permissions = permissions?.map(p => ({ subject: p.resource, action: p.name }))
if (role === 'Administrator') {
  permissions = null
}
if (permissions !== null) {
  // eslint-disable-next-line no-unused-expressions
  permissions?.push({ subject: 'lenon', action: 'access-lenon-app' })
}
export default new Ability(permissions || initialAbility)

import Vue from 'vue'
import { ToastPlugin, ModalPlugin } from 'bootstrap-vue'
import VueCompositionAPI from '@vue/composition-api'
import {
  Dropdown, Menu,
} from 'floating-vue'
import { library } from '@fortawesome/fontawesome-svg-core'
import {
  faFilePdf, faFileWord, faFilePowerpoint, faBook, faFileExcel, faFolder, faFolderPlus, faFolderOpen, faCalendar,
} from '@fortawesome/free-solid-svg-icons'
import {
  faFacebook, faTwitter, faLinkedin, faWhatsapp, faGooglePlus,
} from '@fortawesome/free-brands-svg-icons'
import { FontAwesomeIcon, FontAwesomeLayers, FontAwesomeLayersText } from '@fortawesome/vue-fontawesome'
import VueHtmlToPaper from 'vue-html-to-paper'
import VueCurrencyFilter from 'vue-currency-filter'
import VueSignaturePad from 'vue-signature-pad'
import responsive from 'vue-responsive'
import router from './router'
import store from './store'
import App from './App.vue'
import '@/libs/acl'
// eslint-disable-next-line import/order
import i18n from '@/libs/i18n'
// Global Components
import './global-components'
// 3rd party plugins
import '@/libs/portal-vue'
import '@/libs/toastification'
import { apolloProvider } from './vue-apollo'
import './registerServiceWorker'
import 'floating-vue/dist/style.css'
// 3rd party plugins

library.add(faFolder, faFolderPlus, faBook, faFilePdf, faCalendar, faFolderOpen, faFileWord, faFacebook, faTwitter, faLinkedin, faWhatsapp, faGooglePlus, faFilePowerpoint, faFileExcel)

Vue.component('font-awesome-icon', FontAwesomeIcon)
Vue.component('font-awesome-layers', FontAwesomeLayers)
Vue.component('font-awesome-layers-text', FontAwesomeLayersText)

Vue.use(VueSignaturePad)

// BSV Plugin Registration
Vue.use(ToastPlugin)
Vue.use(ModalPlugin)
Vue.use(responsive)
// Composition API
Vue.use(VueCompositionAPI)
Vue.use(VueHtmlToPaper)
Vue.component('VDropdown', Dropdown)
Vue.component('VMenu', Menu)
Vue.use(VueCurrencyFilter,
  {
    symbol: '',
    thousandsSeparator: ',',
    fractionCount: 2,
    fractionSeparator: '.',
    symbolPosition: 'front',
    symbolSpacing: false,
    avoidEmptyDecimals: '00',
  })
// import core styles

Vue.component('VDropdown', Dropdown)

require('@core/scss/core.scss')

// import assets styles
require('@/assets/scss/style.scss')

Vue.config.productionTip = false
new Vue({
  router,
  store,
  i18n,
  apolloProvider,
  render: h => h(App),
}).$mount('#app')

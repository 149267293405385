// eslint-disable-next-line import/prefer-default-export
export const feePayments = {
  namespaced: true,
  state: {
    feesToPay: [],
    studentsForFeePayment: [],
    feePayments: {},
    arrears: [],
    studentToRecordFeesFor: {},
    previousStudentId: null,
    selectedClassId: null,
    settings: {},
    arrearsSettings: {},
  },
  mutations: {
    setSettings(state, settings) {
      state.settings = settings
    },
    setArrearsSettings(state, settings) {
      state.arrearsSettings = settings
    },
    setSelectedClass(state, id) {
      state.selectedClassId = id
    },
    updateStudentId(state) {
      state.previousStudentId = state.studentToRecordFeesFor.student_id
    },
    setStudentToRecordFeesFor(state, data) {
      state.studentToRecordFeesFor = data
    },
    setArrears(state, data) {
      state.arrears = data.arrears.data
    },
    setFeePayments(state, data) {
      state.feePayments = data.feePayments
    },
    setStudentsForFeePayment(state, data) {
      state.studentsForFeePayment = data.studentsForFeePayment
    },
    updateStudentsForFeePayment: (state, feePayment) => {
      state.studentsForFeePayment = state.studentsForFeePayment.filter(datum => datum.student_id !== feePayment.student_id)
      state.studentsForFeePayment = [feePayment, ...state.studentsForFeePayment]
      state.studentToRecordFeesFor = feePayment
    },
    updateStudentFee: (state, feeSetting) => {
      state.feeSettings = state.feeSettings.map(datum => {
        if (datum.id === feeSetting.id) {
          return feeSetting
        }
        return datum
      })
    },
    updateFeePayment: (state, feePayment) => {
      state.feePayments.data = state.feePayments.data.map(datum => {
        if (datum.id === feePayment.id) {
          return feePayment
        }
        return datum
      })
    },
    removeFeePayments: (state, ids) => {
      state.feePayments.data = state.feePayments.data.filter(datum => !ids.includes(datum.id))
    },
  },
  getters: {
    feesToPay(state) {
      return state.feesToPay
    },
    studentsForFeePayment(state) {
      return state.studentsForFeePayment
    },
    feePayments(state) {
      return state.feePayments
    },
    arrears(state) {
      return state.arrears
    },
    student(state) {
      return state.studentToRecordFeesFor
    },
    previousStudentId(state) {
      return state.previousStudentId
    },
    selectedClass(state) {
      return state.selectedClassId
    },
    previousSettings(state) {
      return state.settings
    },
    previousArrearsSettings(state) {
      return state.arrearsSettings
    },
  },

}

// eslint-disable-next-line import/prefer-default-export
export const tripRequest = {
  namespaced: true,
  state: {
    tripRequests: [],
    selectedClassId: null,
  },
  mutations: {
    setTripRequests(state, data) {
      state.tripRequests = data
    },
    setSelectedClassId(state, data) {
      state.selectedClassId = data
    },
    addTripRequest: (state, tripRequest) => {
      state.tripRequests = [...state.tripRequests, tripRequest]
    },
    updateTripRequest: (state, tripRequest) => {
      state.tripRequests = state.tripRequests.map(datum => {
        if (datum.id === tripRequest.id) {
          return tripRequest
        }
        return datum
      })
    },
    removeTripRequest: (state, id) => {
      state.tripRequests = state.tripRequests.filter(datum => datum.id !== id)
    },
  },
  getters: {
    tripRequests(state) {
      return state.tripRequests
    },
    selectedClassId(state) {
      return state.selectedClassId
    },
  },

}

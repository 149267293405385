// eslint-disable-next-line import/prefer-default-export
export const students = {
  namespaced: true,
  state: {
    students: [],
    selectedStudent: null,
    selectedClassId: null,
    selectedBatchId: null,
    attendanceList: [],
    previousAttendanceListSetting: {},
    previousUnmarkedAttendanceSetting: {},
    unmarkedAttendances: [],
    batches: [],
    oldStudents: [],
  },
  mutations: {
    setPreviousUnmarkedAttendanceSettings(state, setting) {
      state.previousUnmarkedAttendanceSetting = setting
    },
    setUnmarkedAttendances(state, data) {
      state.unmarkedAttendances = data
    },
    setAttendanceListSetting(state, setting) {
      state.previousAttendanceListSetting = setting
    },
    setAttendanceList(state, list) {
      state.attendanceList = list
    },
    setSelectedClass(state, id) {
      state.selectedClassId = id
    },
    setStudent(state, student) {
      state.selectedStudent = student
    },
    setStudents(state, data) {
      state.students = data
    },
    setOldStudents(state, data) {
      state.oldStudents = data
    },
    setSelectedBatch(state, data) {
      state.selectedBatchId = data
    },
    setBatches(state, data) {
      state.batches = data
    },
    addStudent: (state, student) => {
      if (state.selectedClassId && +student.class_id === +state.selectedClassId) {
        state.students = [student, ...state.students]
      }
    },
    updateStudent: (state, student) => {
      state.students = state.students.map(datum => {
        if (datum.id === student.id) {
          return { ...datum, ...student }
        }
        return datum
      }).filter(st => st.class_id === state.selectedClassId)
      state.selectedStudent = student
    },
    removeStudent: (state, id) => {
      state.students = state.students.filter(datum => +datum.id !== +id)
    },
    removeStudentParent: (state, st) => {
      const foundStudent = state.students.find(a => +a.id === +st.id)
      foundStudent.parents = foundStudent.parents.filter(
        studentParent => +studentParent.id !== +st.parentId,
      )
      state.selectedStudent = { ...foundStudent }
    },
  },
  getters: {
    students(state) {
      return state.students
    },
    oldStudents(state) {
      return state.oldStudents
    },
    batches(state) {
      return state.batches
    },
    selectedBatchId(state) {
      return state.selectedBatchId
    },
    attendanceList(state) {
      return state.attendanceList
    },
    previousAttendanceListSetting(state) {
      return state.previousAttendanceListSetting
    },
    previousUnmarkedAttendanceSetting(state) {
      return state.previousUnmarkedAttendanceSetting
    },
    selectedStudent(state) {
      return state.selectedStudent
    },
    selectedClassId(state) {
      return state.selectedClassId
    },
    unmarkedAttendances(state) {
      return state.unmarkedAttendances
    },
  },
}

// eslint-disable-next-line import/prefer-default-export
export const accountUsers = {
  namespaced: true,
  state: {
    roles: [],
    users: [],
    permissions: [],
  },
  mutations: {
    setUsers(state,data){
      state.users = data.users
    },
    removeTeacherSubject(state, ts) {
      const user = state.users.find(u => +u.id === +ts.id)
      user.teacher_subjects = user.teacher_subjects.filter(
        t => +t.id !== +ts.teacherSubId,
      )
    },
    setRolesUsersPermissions(state, data) {
      state.users = data.users
      state.roles = data.roles
      state.permissions = data.permissions
    },
    addUser: (state, user) => {
      state.users = [...state.users, user]
    },
    updateUser: (state, user) => {
      state.users = state.users.map(datum => {
        if (datum.id === user.id) {
          return user
        }
        return datum
      })
    },
    removeUser: (state, id) => {
      state.users = state.users.filter(datum => +datum.id !== +id)
    },
    updateUserStatus(state, user) {
      const foundUser = state.users.find(u => +u.id === +user.id)
      foundUser.active = user.status
    },
    addRole: (state, role) => {
      state.roles = [...state.roles, role]
    },
    updateRole: (state, role) => {
      state.roles = state.roles.map(datum => {
        if (datum.id === role.id) {
          return role
        }
        return datum
      })
    },
    removeRole: (state, id) => {
      state.roles = state.roles.filter(datum => +datum.id !== +id)
    },

    updateRolePermissions(state, role) {
      const foundRole = state.roles.find(r => +r.id === +role.id)
      foundRole.permissions = role.permissions
    },
  },
  getters: {
    users(state) {
      return state.users.map((u)=>({...u,full_name:`${u.first_name} ${u.last_name}`}))
    },
    roles(state) {
      return state.roles
    },
    permissions(state) {
      return state.permissions
    },
  },
}

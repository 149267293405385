// eslint-disable-next-line import/prefer-default-export
export const trip = {
  namespaced: true,
  state: {
    trips: [],
    completedTrips: [],
    attendances: [],
    completedSettings:{
      startDate: null,
      endDate: null,
    },
    attendanceSettings:{
      date: null,
      selectedClassId: null,
      selectedTripTypeId: null
    }
  },
  mutations: {
    setCompletedTrips(state, data) {
      state.completedTrips = data
    },
    setCompletedSettings(state, data) {
      state.completedSettings = data
    },
    setAttendances(state, data) {
      state.attendances = data
    },
    setAttendanceSettings(state, data) {
      state.attendanceSettings = data
    },
    setTrips(state, data) {
      state.trips = data
    },
    addTrip: (state, trip) => {
      state.trips = [...state.trips, trip]
    },
    updateTrip: (state, trip) => {
      state.trips = state.trips.map(datum => {
        if (datum.id === trip.id) {
          return trip
        }
        return datum
      })
    },
    removeTrip: (state, id) => {
      state.trips = state.trips.filter(datum => datum.id !== id)
    },
  },
  getters: {
    trips(state) {
      return state.trips
    },
    completedTrips(state) {
      return state.completedTrips
    },
    tripAttendances(state) {
      return state.attendances
    },
    attendanceSettings(state){
      return state.attendanceSettings
    },
    completedSettings(state){
      return state.completedSettings
    }
  },

}

export default [
  {
    path: '/academics',
    component: () => import('@/layouts/GeneralLayout.vue'),
    children: [
      {
        path: 'assessments',
        name: 'assessments',
        component: () => import('@/views/academics/assessments/Index.vue'),
        meta: {
          redirectIfGuest: true,
          resource: 'academics',
          action: 'access-student-assessments',
        },
      },
      // academics
      {
        path: 'results',
        name: 'results',
        component: () => import('@/views/academics/results/Index.vue'),
        meta: {
          redirectIfGuest: true,
          resource: 'academics',
          action: 'access-report-cards',
        },
      },
      {
        path: 'performances',
        name: 'performances',
        component: () => import('@/views/academics/performances/Index.vue'),
        meta: {
          redirectIfGuest: true,
          resource: 'academics',
          action: 'access-performances',
        },
      },
      {
        path: 'guide',
        name: 'academics-guide',
        component: () => import('@/views/admin/Index.vue'),
        meta: {
          redirectIfGuest: true,
          resource: 'lenon',
          action: 'access-lenon-app',
        },
      },
    ],
  },
]

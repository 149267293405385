// eslint-disable-next-line import/prefer-default-export
export const dailyReports = {
  namespaced: true,
  state: {
    reportForms: [],
    terminalForms: [],
    dailyReports: [],
    settings: {
      selectedDate: null,
      selectedClassId: null,
      reportType: null,
    },
  },
  mutations: {
    setSettings(state, data) {
      state.settings = { ...state.settings, ...data }
    },
    setDailyReports(state, reports) {
      state.dailyReports = reports
    },
    setReportForms(state, reportForms) {
      state.reportForms = reportForms
    },
    addReportForm: (state, reportForm) => {
      state.reportForms = [...state.reportForms, reportForm]
    },
    updateReportForm: (state, reportForm) => {
      state.reportForms = state.reportForms.map(datum => {
        if (datum.id === reportForm.id) {
          return reportForm
        }
        return datum
      })
    },
    removeReportForm: (state, id) => {
      state.reportForms = state.reportForms.filter(datum => +datum.id !== +id)
    },

    setTerminalForms(state, reportForms) {
      state.terminalForms = reportForms
    },
    addTerminalReportForm: (state, reportForm) => {
      state.terminalForms = [...state.terminalForms, reportForm]
    },
    updateTerminalReportForm: (state, reportForm) => {
      state.terminalForms = state.terminalForms.map(datum => {
        if (datum.id === reportForm.id) {
          return reportForm
        }
        return datum
      })
    },
    removeTerminalReportForm: (state, id) => {
      state.terminalForms = state.terminalForms.filter(datum => +datum.id !== +id)
    },
  },
  getters: {
    reportForms(state) {
      return state.reportForms
    },
    terminalForms(state) {
      return state.terminalForms
    },
    dailyReports(state) {
      return state.dailyReports
    },
    settings(state) {
      return state.settings
    },
  },
}

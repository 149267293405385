import Vue from 'vue'
import VueRouter from 'vue-router'
// New Imports
import { canNavigate } from '@/libs/acl/routeProtection'

// eslint-disable-next-line import/no-cycle
import store from '../store'
import auth from './auth'
import admin from './admin'
import students from './students'
import finance from './finance'
import academics from './academics'
import accounts from './accounts'
import dashboard from './dashboard'
import staff from './staff'
import documents from './documents'
import transportation from './transportation'

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior() {
    return {
      x: 0,
      y: 0,
    }
  },
  routes: [
    {
      path: '/',
      component: () => import('@/layouts/lenon/HomeLayout.vue'),
      children: [
        {
          path: '',
          name: 'home',
          component: () => import('@/views/Home.vue'),
          meta: {
            layout: 'full',
            resource: 'lenon',
            action: 'access-lenon-app',
            redirectIfGuest: true,
          },
        },
      ],
    },
    ...auth,
    ...admin,
    ...students,
    ...finance,
    ...academics,
    ...accounts,
    ...dashboard,
    ...staff,
    ...documents,
    ...transportation,
    {
      path: '/error-404',
      name: 'error-404',
      component: () => import('@/views/error/Error404.vue'),
      meta: {
        layout: 'full',
        resource: 'lenon',
        action: 'access-lenon-app',
      },
    },
    {
      path: '/unauthorized',
      name: 'unauthorized',
      component: () => import('@/views/error/UnAuthorized.vue'),
      meta: {
        layout: 'full',
        resource: 'lenon',
        action: 'access-lenon-app',
      },
    },
    {
      path: '/school-not-verified',
      name: 'unverified',
      component: () => import('@/views/error/Unverified.vue'),
      meta: {
        layout: 'full',
        resource: 'lenon',
        action: 'access-lenon-app',
        redirectIfVerified: true,
      },
    },
    {
      path: '*',
      redirect: 'error-404',
      meta: {
        resource: 'lenon',
        action: 'access-lenon-app',
      },
    },
  ],
})

// ? For splash screen
// Remove afterEach hook if you are not using splash screen
router.afterEach(() => {
  // Remove initial loading
  const appLoading = document.getElementById('loading-bg')
  if (appLoading) {
    appLoading.style.display = 'none'
  }
})

router.beforeEach(async (to, _, next) => {
  await store.restored
  // const isLoggedIn = true
  if (to.meta.resource) {
    localStorage.setItem(to.meta.resource, to.name)
  }
  const isLoggedIn = !!store.getters['auth/accessToken']
  const verified = !!store.getters['auth/verified']

  if ((isLoggedIn && !verified) && to.name !== 'unverified') {
    return next({ name: 'unverified' })
  }
  if (verified && to.meta.redirectIfVerified) {
    return next('/')
  }
  if (!canNavigate(to)) {
    // If not logged in => login
    if (!isLoggedIn) return next({ name: 'login' })
    // If logged in => not authorized
    return next({ name: 'unauthorized' })
  }
  // Redirect if logged in
  if (to.meta.redirectIfGuest && !isLoggedIn) {
    return next({ name: 'login' })
  }
  // Redirect if logged in
  if (to.meta.redirectIfLoggedIn && isLoggedIn) {
    return next('/')
  }

  return next()
})
export default router

// eslint-disable-next-line import/prefer-default-export
export const recurringFeeSettings = {
  namespaced: true,
  state: {
    recurringFeeSettings: [],
    recurringFeePayments: [],
    settings: {},
  },
  mutations: {
    setSettings(state, settings) {
      state.settings = settings
    },
    setRecurringFeeSettings(state, data) {
      state.recurringFeeSettings = data.recurringFeeSettings
    },
    setRecurringFeePayments(state, data) {
      state.recurringFeePayments = data.recurringFeePayments
    },
    addRecurringFeeSetting: (state, recurringFeeSetting) => {
      state.recurringFeeSettings = [...state.recurringFeeSettings, recurringFeeSetting]
    },
    updateRecurringFeeSetting: (state, recurringFeeSetting) => {
      state.recurringFeeSettings = state.recurringFeeSettings.map(datum => {
        if (datum.id === recurringFeeSetting.id) {
          return recurringFeeSetting
        }
        return datum
      })
    },
    updateRecurringFeePayment: (state, recurringFeePayment) => {
      state.recurringFeePayments = state.recurringFeePayments.map(datum => {
        if (datum.student_id === recurringFeePayment.student_id) {
          return recurringFeePayment
        }
        return datum
      })
    },
    removeRecurringFeeSetting: (state, id) => {
      state.recurringFeeSettings = state.recurringFeeSettings.filter(datum => datum.id !== id)
    },
  },
  getters: {
    recurringFeeSettings(state) {
      return state.recurringFeeSettings
    },
    recurringFeePayments(state) {
      return state.recurringFeePayments
    },
    previousSettings(state) {
      return state.settings
    },
  },

}
